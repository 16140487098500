/*
* Welcome to your app's main JavaScript file!
*
* We recommend including the built version of this JavaScript file
* (and its CSS file) in your base layout (base.html.twig).
*/

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';
import "slick-carousel";

// start the Stimulus application
// import './bootstrap';

// any CSS you import will output into a single css file (app.css in this case)
import './scss/app.scss';

//////////////////////////////////////////// COOKIES BANNER ////////////////////////////////////////////

function isScriptLoaded(url) {
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
        if (scripts[i].src === url || scripts[i].getAttribute('data-tag') === url) {
            return true;
        }

    }
    return false;
}

function allowCookies() {
    const env = $('body').data('env');
    const gtmcode = $('body').data('gtmcode');

    if (env === 'dev') {
        const analyticsCodes = [
            {
                url: 'https://www.googletagmanager.com/gtm.js?id=',
                code: gtmcode,
                isGtm: true
            },
        ];

        const standardScripts = [
            {
                url: '#identifier',
                isInline: true,
                code: "alert();"
            }
        ]

        $.each(analyticsCodes, function (index, value) {
            const urlToLoad = value.url + value.code;

            if (!isScriptLoaded(urlToLoad)) {
                const s = document.createElement('script');
                s.setAttribute('src', urlToLoad);
                s.async = true;
                document.head.appendChild(s);

                window.dataLayer = window.dataLayer || [];

                if (value.isGtm) {
                    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                } else {
                    window.gtag = window.gtag || function () {
                        window.dataLayer.push(arguments);
                    };

                    window.gtag('consent', 'default', {
                        'ad_storage': 'granted',
                        'analytics_storage': 'granted'
                    });

                    window.gtag('js', new Date());
                    window.gtag('config', value.code);
                }
            }
        });

        $.each(standardScripts, function (index, value) {
            const urlToLoad = value.url;

            if (!isScriptLoaded(urlToLoad)) {
                const s = document.createElement('script');

                if (!value.isInline) {
                    s.setAttribute('src', urlToLoad);
                } else {
                    s.setAttribute('data-tag', urlToLoad);
                    const i = document.createTextNode(value.code);
                    s.appendChild(i);
                }

                document.head.appendChild(s);
            }
        });
    }
}

$(function () {
    if (localStorage.getItem('popState') === 'shown') {
        $("#cookies-bar").hide();
        $("#cookies-bar-tab").show();
    }

    if (localStorage.getItem('acceptedCookies') === 'true') {
        allowCookies();
    }

    $('#cookies-bar-tab').on('click', function (e) {
        $("#cookies-bar").fadeIn();
        $("#cookies-bar-tab").fadeOut();
    });

    $('#cookies-bar--close--accept').on('click', function (e) {
        localStorage.setItem('popState', 'shown');
        localStorage.setItem('acceptedCookies', true);
        allowCookies();

        $('#cookies-bar').fadeOut();
        $("#cookies-bar-tab").fadeIn();
    });

    $('#cookies-bar--close--reject').on('click', function (e) {
        localStorage.setItem('popState', 'shown');
        localStorage.setItem('acceptedCookies', false);

        $('#cookies-bar').fadeOut();
        $("#cookies-bar-tab").fadeIn();
    });
});

//////////////////////////////////////////// NAVIGATION MENU ////////////////////////////////////////////

// desktop nav works using css only
// js used to turn it into a mobile nav and back again

$('.site-navbar__list').find('ul > li > ul').parent().addClass('parent-link')

const $hamburgerBtn = $('.hamburger')
const $navbarList = $('.site-navbar__list')
const $siteHeaderContent = $('.site-header__content')

// mobile menu
let menuOpen = false;

function openMobileMenu() {
    $siteHeaderContent.addClass('dark-bg') // as the mobile menu is opened the bg needs changing from dark to a gradient
    $hamburgerBtn.addClass('is-active').attr('aria-expanded', 'true')
    $navbarList.slideDown()
    menuOpen = true;
    $('body').addClass('js-mobile-menu-open') // disabled scrolling on body so mobile menu can be scrolled
}

function closeMobileMenu() {

    $hamburgerBtn.removeClass('is-active').attr('aria-expanded', 'false')
    $navbarList.slideUp()
    setTimeout(() => { // change gradient bg back to dark -- set time out prevents it blinking
        $siteHeaderContent.removeClass('dark-bg')
    }, 400)

    menuOpen = false;

    $('body').removeClass('js-mobile-menu-open') // enable scrolling on body
}

$hamburgerBtn.on('click', function () {
    if (!menuOpen) {
        openMobileMenu()
    } else {
        closeMobileMenu()
    }
})

$(window).on('resize', function () {
    const width = $(window).width()

    if ($(window).width() != width) {
        if (screen.width > 991 && window.innerWidth > 991) {
            $navbarList.css('display', 'flex')
        } else {
            $navbarList.css('display', 'none')
            closeMobileMenu()
        }
    }

})

//////////////////////////////////////////// SCROLLING ////////////////////////////////////////////

let scroll_pos = 0;

$(document).on('scroll', function () {
    scroll_pos = $(this).scrollTop();
    if (scroll_pos <= 11) {
        $('.site-header').removeClass('site-header--dark')
    } else {
        $('.site-header').addClass('site-header--dark')
    }
});

//////////////////////////////////////////// SLIDERS ////////////////////////////////////////////

$(".testimonial-slider").slick({
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
});

$(".statistics-slider").slick({
    infinite: true,
    slidesToShow: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

//////////////////////////////////////////// ACCORDION //////////////////////////////////////////// 

// for the projects page

const $accordionBtn = $('.accordion-button')

$accordionBtn.on('click', function () {
    let ariaExpanded = $(this).attr('aria-expanded')
    ariaExpanded === 'false' ? $(this).attr('aria-expanded', 'true') : $(this).attr('aria-expanded', 'false')
    $(this).next().slideToggle()
})


//////////////////////////////////////////// INLINE EDITOR FIX //////////////////////////////////////////// 

// some flex and transition classes were messing up the inline editor so have just removed them here on the affected elements
$(() => {
    // only run on correct page
    if (window.location.pathname == '/' || window.location.pathname == '/home') {
        const $editorLinksToFix = $('.home-service-links').find('.inlineEditorContainer')
        // only run if logged into cms 
        if ($editorLinksToFix.length) {
            $editorLinksToFix.parent().removeClass('flex').prev().css('margin-bottom', 0).removeClass('flex').parent().removeClass('flex')
            $('.home-service-links').find('.zoom-effect-container').removeClass('zoom-effect-container').find('.card-image').removeClass('card-image')
        }
    }
})




